import {Toast} from "vant";

export function toastSuccess(msg) {
    Toast.success({
        message: msg
    })
}

export function toastFail(msg) {
    Toast.fail({
        message: msg
    })
}