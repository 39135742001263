<template>
  <div class="contr-content no_touch">
    <van-uploader class="avatar"
                  v-model="fileList"
                  max-count="1"
                  :after-read="afterRead"
                  :preview-full-image="false"/>

    <van-field style="margin-top: 30px" v-model="profile.Nickname" label="姓名" placeholder="请输入姓名"/>
    <van-field v-model="profile.Sim" label="号码" placeholder="请输入手环SIM号码"/>
    <van-field v-model="gender"
               label="性别"
               placeholder="请选择性别"
               is-link readonly
               @click="showGender = true"/>
    <van-field v-model="profile.Birthday"
               label="生日"
               placeholder="请选择生日"
               is-link readonly
               @click="showTimePopup = true"/>
    <van-field v-model="profile.Height" label="身高(CM)" placeholder="请输入身高"/>
    <van-field v-model="profile.Weight" label="体重(KG)" placeholder="请输入体重"/>

    <van-action-sheet v-model="showGender" :actions="actions" @select="onSelect"/>
    <van-popup v-model="showTimePopup" position="bottom" :style="{ height: '38%' }">
      <van-datetime-picker
          v-model="birthday"
          type="date"
          @confirm="showTimePopup = false"
          @cancel="showTimePopup = false"
          :min-date="minDate"
          :max-date="maxDate"/>
    </van-popup>

    <van-button class="button" plain type="info" @click="saveUserProfile" square>保存</van-button>
  </div>
</template>

<script>
import {post} from "@/router/httpUtils";
import {toastFail, toastSuccess} from "@/util/toastUtil";
import {State_0} from "@/constants/contants";
import Vue from 'vue';
import {Uploader, Field, ActionSheet, Toast} from 'vant';
import {date2Str, dateAddMonth, str2Date} from "@/util/timeUtil";

Vue.use(Field);
Vue.use(Uploader);
Vue.use(ActionSheet);

export default {
  name: "EditUserInfo",
  data() {
    return {
      showGender: false,
      actions: [{name: '男'}, {name: '女'}],
      gender: '',

      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 12, 31),
      birthday: new Date(),
      showTimePopup: false,

      fileList: [],
      profile: {
        // "Nickname": "Qin",
        // "UpdateTime": "2021-03-31 00:52:44",
        // "Weight": 100.0,
        // "UserId": 0,
        // "Sim": "123",
        // "Age": 0,
        // "CellPhone": "",
        // "CellPhone2": "",
        // "Address": "",
        // "Breed": "",
        // "IDnumber": "",
        // "Remark": "",
        // "MarkerColor": 0,
        // "Notes": "",
        // "Organ": 0

        // "Grade": "",
        // "Height": 180,
        // "Gender": 1,
        // "Drug": "",
        // "DeviceID": 2664821,
        // "Condition": "",
        // "Birthday": "2021-01-14 00:00:00",
        // "BloodType": "",
        // "Avatar": "http://api.xiaoxinhuan.com/UserAvatar/avatar_d_2664821.jpg?t=20210324204915309",
        // "Area": "",
        // "Allergic": "",
      }
    }
  },
  mounted() {
    this.getUserProfile()
  },
  watch: {
    profile: {
      handler(data) {
        console.log(data)
        this.gender = data.Gender == 1 ? '男' : '女'
      },
      deep: true
    },
    birthday: {
      handler(date) {
        console.log(date)

        let month = date.getMonth() + 1
        if (String(month).length === 1) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (String(day).length === 1) {
          day = '0' + String(day)
        }
        this.profile.Birthday = date.getFullYear() + '-' + month + '-' + day
      },
      deep: true
    },
  },
  methods: {
    onSelect(item) {
      console.log(item)
      this.showGender = false
      this.profile.Gender = item.name === '男' ? 1 : 2
    },
    afterRead(file) {
      console.log(file)
      this.profile.Avatar = file.content.substring(file.content.indexOf('base64,') + 7, file.content.length)
    },
    getUserProfile() {
      post('/api/Person/GetPersonProfile', {
        "DeviceId": localStorage.DeviceId,
        "Token": localStorage.Token,
        "UserId": localStorage.UserId
      }).then(res => {
        console.log(res)
        if (res.State != State_0) {
          toastFail('网络异常')
          return
        }

        this.profile = res.Item
        this.birthday = str2Date(res.Item.Birthday)
        this.fileList = [{url: res.Item.Avatar}]
        // this.gender = res.Item.Gender == 1 ? '男' : '女'
      }).catch(err => {
        toastFail(err)
      })
    },
    saveUserProfile() {
      Toast.loading({
        message: '保存中...'
      })
      // if (!this.profile.Avatar) {
      //   toastFail('请选择头像')
      //   return
      // }
      const params = {
        "Item": {
          "Allergic": "",
          "Area": "",
          "Avatar": this.profile.Avatar.startsWith('http') ? '' : this.profile.Avatar,
          // "Avatar": '',
          "Birthday": this.profile.Birthday,
          "BloodType": "",
          "Condition": "",
          "DeviceID": localStorage.DeviceId,
          "Drug": "",
          "Gender": this.profile.Gender,
          "Grade": "",
          "Height": this.profile.Height,
          "Id": -1,
          "Nickname": this.profile.Nickname,
          "Notes": "",
          "Organ": 0,
          "Sim": this.profile.Sim,
          "UpdateTime": date2Str(dateAddMonth(new Date())),
          "UserId": localStorage.UserId,
          "Weight": this.profile.Weight
        },
        "Token": localStorage.Token
      }
      // const urlp = getURLParams(params)
      // console.log(urlp)
      // const b = urlp.replace('Item[Avatar]=', 'Item[Avatar]=' + this.profile.Avatar)
      // console.log(urlp)
      // console.log(b)

      post('/api/Person/SavePersonProfile', params).then(res => {
        if (res.State == State_0) {
          toastSuccess('信息保存成功!')
        } else {
          toastFail('信息保存失败!')
        }
      }).catch(err => {
        toastFail(err)
      })
    },
  }
}
</script>

<style>
.van-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.van-uploader__upload {
  width: 120px;
  height: 120px;
  margin: 0;
}

</style>
<style scoped>
.container {
}

.contr-content {
  overflow: auto;
  height: 100vh;

  /*下面三行让图片居中*/
  vertical-align: middle;
  text-align: center;
}

.avatar {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-top: 40px;
}

.user-name {
  margin-top: 20px;
}

.button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>